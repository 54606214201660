.string-search * {
	color: white;
	font-family: "Inter", sans-serif;
	text-align: center;
}

.string-search code {
	background: rgba(255, 255, 255, 0.25);
	border-radius: 0.25rem;
	color: black;
	display: inline-block;
	letter-spacing: 0.1ch;
	padding: 0 0.4rem;
}

.string-search footer {
	background: black;
	display: flex;
	height: 2.5rem;
	justify-content: right;
	padding: 0.5rem;
	padding-right: 0.75rem;
	width: 100%;
}

.string-search footer a {
	text-decoration: underline;
}

.string-search footer h3 {
	font-size: 1.25rem;
}

.string-search h1 {
	font-size: 3rem;
}

.string-search h2 {
	font-size: 2.5rem;
}

.string-search h3 {
	font-size: 2rem;
}

.string-search img {
	width: 30rem !important;
}

.string-search p {
	font-size: 2rem;
}

.string-search .search-visualizer span,
.string-search .text-box code {
	color: black;
	font-family: Monospace !important;
}

.string-search .text-box p {
	background: rgba(255, 255, 255, 0.25);
	border-radius: 2rem;
	box-shadow: -0.75rem 0.75rem black;
	font-size: 1.5rem;
	padding: 1.5rem;
}

.string-search #background {
	background: rgb(0, 150, 255);
	display: flex;
	justify-content: center;
	min-height: 100vh;
}

.string-search #main-container {
	align-items: center;
	display: flex;
	flex-direction: column;
	gap: 2rem;
	height: 100%;
	padding: 2rem 0;
	width: 50%;
}

.string-search #main-container > * {
	width: 100%;
}

@media all and (min-width: 481px) and (max-width: 1099px) {
	.string-search #main-container {
		width: 80%;
	}
}

@media all and (max-width: 480px) {
	.string-search img {
		width: 100% !important;
	}

	.string-search .text-box p {
		font-size: 1.25rem;
	}

	.string-search #main-container {
		width: 90%;
	}
}
