.projects .button-container {
	align-items: center;
	display: flex;
	justify-content: space-around;
	width: 100%;
}

.projects .button-container a {
	text-decoration: none !important;
}

.projects .button-container p {
	background-color: red;
	border-radius: 1rem;
	font-size: 1.25rem !important;
	padding: 0.75rem;
	transition: box-shadow 0.25s;
}

.projects .multi-item-container {
	display: flex;
	gap: 1rem;
}

.projects .multi-item-container div {
	display: flex;
	flex-direction: column;
	gap: 1rem;
}

.projects .multi-item-container img {
	width: 100% !important;
	transition: box-shadow 0.25s;
}

.projects #illustrations {
	display: flex;
	height: 100%;
}

.projects #illustrations img {
	transition: width 0.5s;
}

@media (hover: hover) {
	.projects .button-container p:hover {
		box-shadow: 0 0 0.5rem white;
	}

	.projects .multi-item-container img:hover {
		box-shadow: 0 0 0.75rem white;
	}

	.projects #illustrations img:hover {
		width: 100%;
	}
}

@media all and (max-width: 768px) {
	.projects .button-container {
		flex-direction: column;
		gap: 1rem;
	}

	.projects .multi-item-container {
		flex-direction: column;
	}

	.projects #illustrations {
		flex-direction: column;
		height: auto;
		gap: 1rem;
	}
}
