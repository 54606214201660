.roomme * {
	box-sizing: border-box;
	font-family: "Inter", sans-serif;
	font-weight: 400;
	margin: 0;
	padding: 0;
}

.roomme footer {
	justify-content: center;
	margin-top: 5rem;
}

.roomme h1 {
	font-size: 2.5rem;
	font-weight: 500;
}

.roomme header {
	justify-content: space-between;
	position: fixed;
	top: 0;
}

.roomme header,
.roomme footer {
	align-items: center;
	background: rgb(0, 176, 80);
	display: flex;
	height: 75px;
	width: 100%;
	z-index: 10;
}

.roomme header button {
	background: white;
	border: none;
	border-radius: 0.75rem;
	color: rgb(0, 176, 80);
	font-size: 1rem;
	font-weight: 600;
	margin: 10%;
	padding: 1rem 2rem;
	white-space: nowrap;
}

.roomme iframe {
	border: none;
	margin: 0 10%;
}

.roomme main {
	margin-top: 75px;
}

.roomme .logo {
	align-items: center;
	display: flex;
	height: 40px;
	margin: 10%;
}

.roomme .logo h3 {
	color: white;
	font-size: 2rem;
	margin: 5px;
}

.roomme .logo img {
	height: 100%;
}

.roomme .text {
	background: rgb(0, 176, 80);
	border-radius: 1rem;
	box-shadow: 0 0 50px 1rem #00d96e;
	color: white;
	padding: 3rem;
	width: 80%;
}

.roomme .text h1 {
	margin-bottom: 2rem;
}

.roomme .text-and-image-container {
	padding: 1rem 0;
}

.roomme .text-and-image-container,
.roomme #hero-container {
	align-items: center;
	display: flex;
	justify-content: center;
	top: 75px;
	width: 100%;
}

.roomme .text-and-image-container img,
.roomme #hero-container img {
	height: 100%;
	object-fit: cover;
	max-width: 40%;
}

.roomme .text-container {
	align-items: center;
	display: flex;
	justify-content: center;
	width: 40%;
}

.roomme #about {
	background: rgb(0, 176, 80);
	color: white;
	padding: 2rem;
	text-align: center;
}

.roomme #about h1 {
	margin-bottom: 1rem;
}

.roomme #about h1,
.roomme #about h2 {
	margin: auto;
	width: 80%;
}

.roomme #hero-container {
	height: calc(100vh - 75px);
	top: 75px;
}

.roomme #hero-container h1 {
	font-size: 4rem;
}

.roomme #transition {
	padding: 5rem 0 5rem 0;
	text-align: center;
	width: 100%;
}

@media all and (max-width: 1280px) {
	.roomme .text-container {
		width: 50%;
	}

	.roomme #hero-container img {
		max-width: none;
		width: 50%;
	}
}

@media all and (max-width: 768px) {
	.roomme * {
		font-weight: 400;
	}

	.roomme footer .logo h3 {
		font-size: 1rem;
	}

	.roomme h1 {
		font-size: 2rem !important;
	}

	.roomme h2 {
		font-size: 1rem;
	}

	.roomme header button {
		margin: 5%;
	}

	.roomme .logo {
		margin: 5%;
	}

	.roomme .text {
		padding: 1rem;
	}

	.roomme .text-and-image-container {
		flex-direction: column;
		gap: 2rem;
	}

	.roomme .text-and-image-container img {
		object-fit: contain;
	}

	.roomme .text-and-image-container img,
	.roomme #hero-container img {
		max-width: none;
		width: 75%;
	}

	.roomme .text-container {
		width: 100%;
	}

	.roomme #hero-container {
		gap: 2rem;
		padding-bottom: 2rem;
	}

	.roomme #hero-container,
	.roomme #step-1,
	.roomme #step-3 {
		flex-direction: column-reverse;
	}

	.roomme #transition {
		margin: auto;
		width: 80%;
	}
}
