.string-search .table {
	background: rgba(255, 255, 255, 0.25);
	border-radius: 2rem;
	box-shadow: -0.75rem 0.75rem black;
}

.string-search table {
	border: 0;
	border-radius: 2rem;
	border-spacing: 0;
	width: 100%;
}

.string-search .table-container {
	padding: 1.5rem;
}

.string-search table {
	border: 0.25rem solid;
	overflow: hidden;
}

.string-search td {
	transition: background-color 0.25s;
}

.string-search td,
.string-search th {
	border-radius: 2rem;
	font-size: 1.5rem;
	padding: 0.5rem;
}

.string-search thead tr {
	background-color: rgba(255, 255, 255, 0.1);
}

.string-search tr:nth-child(even) {
	background-color: rgba(255, 255, 255, 0.1);
}

@media all and (max-width: 480px) {
	.string-search table {
		display: block;
		overflow: scroll;
	}

	.string-search th,
	.string-search td {
		font-size: 1.25rem;
	}
}
